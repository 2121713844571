import querystring from "querystring";

import jsonwebtoken from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";

import * as logger from "../logger";

import { prepareHeader } from "./utils";

const jsonWebKeys = JSON.parse(process.env.jsonWebKeys || "[]");

const decodeTokenHeader = (token: string): any => {
  const [headerEncoded] = token.split(".");
  const buff = Buffer.from(headerEncoded, "base64");
  const text = buff.toString("ascii");

  return JSON.parse(text);
};

const getJsonWebKeyWithKID = (kid: string): { kty: string; e: string; use: string; kid: string; alg: string; n: string } | null => {
  for (const jwk of jsonWebKeys) {
    if (jwk.kid === kid) {
      return jwk;
    }
  }

  return null;
};

const verifyJsonWebTokenSignature = (token, jsonWebKey) => {
  const pem = jwkToPem(jsonWebKey);

  return jsonwebtoken.verify(token, pem, { algorithms: ["RS256"] });
};

export const validateToken = async (token: string): Promise<{email: string | undefined, group: string | undefined, code: "OK" | "AUTHEXPIRED" | "INVALIDPERMISSION"}> => {
  let result: {email: string | undefined, group: string | undefined, code: "OK" | "AUTHEXPIRED" | "INVALIDPERMISSION"};

  try {
    const header = decodeTokenHeader(token);
    const jsonWebKey = getJsonWebKeyWithKID(header.kid);
    const decodedToken = await verifyJsonWebTokenSignature(token, jsonWebKey);

    result = {
      email: decodedToken["https://www.legup.care/email"],
      group: decodedToken["https://www.legup.care/group"],
      code: (Math.floor(Date.now().valueOf() / 1000) < decodedToken.exp) ? "OK" : "AUTHEXPIRED",
    };
  }
  catch (err) {
    logger.error(err, "Error decoding token");
    result = { email: undefined, group: undefined, code: "INVALIDPERMISSION" };
  }

  console.log(result);

  return result;
};

export const generateKinsideJWT = async (email: string, groups: any, id_token: string, pid?: string, redirectPath?: string) => {
  let jwt: string | undefined;
  const headers: Headers = prepareHeader({ id_token, email, groups });
  const params = { email, pid, redirectPath };
  const result = await fetch(`/api/auth/kinsideToken?${querystring.stringify(params)}`, { headers });
  if (result.status === 200) {
    const data = await result.json();

    if (data.success) jwt = data.jwt;
  } 
  else {
    logger.error(result.status, "Error generating token");
  }

  return jwt;
};
