import React from "react";

import { Address, AgeGroup, Center, CenterQuestion, Child, Parent } from "@legup/legup-model";

import { Theme, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { Alert } from "@legup/legup-react-components";

import strings from "../../infra/constants/strings";

import FormHeader from "./FormHeader";
import FormFamilyInfo from "./FormFamilyInfo";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: "100%",
  },
  footer: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 700,
    fontStyle: "italic",
    "align-items": "center",
  },
}));

type SignupFormProps = {
  form: any;
  email: string;
  metadata?: string;
  partnerName: string;
  onComplete: (data: { parent: Parent, parent2?: Parent, child: Child }, metadata?: string) => void;
};

const SignupForm = (props: SignupFormProps) => {
  const buildForm = () => {
    // Build up the appropriate model objects
    const form = props.form || {};

    (form.providers || []).forEach(p => {
      const ageGroups: AgeGroup[] = [];
      (p.ageGroups || []).forEach((a: any) => {
        const ageGroup = new AgeGroup();
        ageGroup.buildFromJSON(a);
        ageGroups.push(ageGroup);
      });
      p.ageGroups = ageGroups;

      const centers: Center[] = [];
      (p.centers || []).forEach((c: any) => {
        const center = new Center();
        center.buildFromJSON(c);
        centers.push(center);
      });
      p.centers = centers;

      const questions: CenterQuestion[] = [];
      (p.questions || []).forEach((q: any) => {
        const question = new CenterQuestion();
        question.buildFromJSON(q);
        questions.push(question);
      });
      p.questions = questions;
    });

    return form;
  };

  const classes = useStyles(props);
  const [form, setForm] = React.useState(buildForm());
  const [alertMessage, setAlertMessage] = React.useState<string>();

  React.useEffect(() => {
    setForm(buildForm());
  }, [props.form]);

  const handleSaveFamilyInfo = async (parents: Parent[], child: Child, preferredDate: Date, comments: string, custom_answers: any) => {
    // First, verify the address is in the territory covered
    const addressCheck: { valid: boolean, reason: string } = await verifyAddress(parents[0].address);

    if (!addressCheck.valid) {
      setAlertMessage(addressCheck.reason);

      return;
    }

    // OK, let's save this info!
    const entry: any = { id: { id: form.partner_id } };

    // Extract parent fields
    entry.parent = parents[0];
    if ((parents.length > 1) && parents[1].email) {
      entry.parent2 = parents[1];
    }

    // Extract child
    // Set Child is born right
    const birth_date: Date | undefined = child.birth_date ? new Date(child.birth_date) : undefined;
    if (!birth_date) {
      child.born = "trying";
    }
    else {
      child.born = (birth_date > new Date()) ? "expecting" : "born";
    }
    entry.child = child;

    // And extract the comments, custom answers, and preferred enrollment date
    entry.partnerList = {
      partner_id: form.partner_id,
      list_id: form.partner?.list_id,
      preferred_date: preferredDate,
      comments,
      custom_answers,
      questions: (form.questions || []).map(q => (
        { question: q.question, id: q.id }
      )),
    };

    await addWaitlistSpot(form.partner_id, entry);
  };

  const verifyAddress = async (address: Address) => {
    let valid: boolean = false;
    let reason: string | undefined;

    try {
      const resp = await fetch(`/api/partner/${form.partner_id}/verifyAddress`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ address }),
      });

      if (resp.status === 200) {
        const d2 = await resp.json();
        if (d2.success) {
          valid = d2.inGeo || d2.geoDefinition.type === "none";
          if (!valid) {
            reason = (d2.geoDefinition.type === "state" || d2.geoDefinition.type === "county")
              ? strings.signup.notInGeoDefinition.replace("{Value}", d2.geoDefinition.values.join(" or "))
              : strings.signup.notInGeo.replace("{Name}", form.name);
          }
        }
      }
      else {
        reason = strings.errorCodes.APIERROR;
      }
    }
    catch (e) {
      // Well, we tried
      valid = false;
      reason = strings.signup.notInGeo.replace("{Name}", form.name);
    }

    return { valid, reason };
  };

  const addWaitlistSpot = async (partner_id: string, entry: any): Promise<boolean> => {
    let success: boolean = false;

    try {
      const resp = await fetch(`/api/partner/${partner_id || "0"}/signup`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify([entry]),
      });

      let errorCode: string | undefined;
      if (resp.status === 200) {
        const d2 = await resp.json();
        success = d2.success;
      }

      if (success) {
        // OK, we're done!
        props.onComplete({ parent: entry.parent, parent2: entry.parent2, child: entry.child }, props.metadata);
      }
    }
    catch (e) {
      // Well, we tried
      success = false;
    }

    // In case of failure, pop up an alert message
    if (!success) {
      setAlertMessage(strings.signup.errorForm);
    }

    return success;
  };

  const renderCopyright = () => (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://kinside.com/">
        {strings.companyName}
      </Link>{" "}
      {new Date().getFullYear()}
      .
    </Typography>
  );

  const renderContent = () => (
    <FormFamilyInfo
      form={form}
      email={props.email}
      center={props.partnerName}
      allowNavigator={false}
      questionList={form?.questions}
      questionSectionHeader={strings.signup.sectionPartnerQuestions}
      buttonText={strings.signup.buttonPartnerSignup}
      collapsableSections={["parent2", "subsidy", "spot"]}
      onSave={handleSaveFamilyInfo}
    />
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth="md">
        <main className={classes.content}>
          {!!form && (
            <FormHeader
              address={form.address}
              name={form.name}
              shortcode={form.brand}
              entity="partner"
              doneState={false}
              providers={form.providers}
              logo_url={form.logo_url}
              header={form.header}
            />
          )}
          <Box height="100%" maxWidth="lg">
            {renderContent()}
            <Typography variant="subtitle1" className={classes.footer}>
              {strings.signup.footerPartner.replace("{Name}", form.name)}
            </Typography>
          </Box>
        </main>
        <Alert
          open={!!alertMessage}
          onClose={() => setAlertMessage(undefined)}
          title={strings.alertTitle}
          description={alertMessage}
        />
        <div className="py-4">
          <Divider />
          {renderCopyright()}
        </div>
      </Container>
    </div>
  );
};

export default SignupForm;
