import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";

import { kinsideTokens } from "@legup/legup-react-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faXmark,
} from "@fortawesome/pro-light-svg-icons";

import {
  faCirclePlay,
  faCircleQuestion,
} from "@fortawesome/pro-solid-svg-icons";

// make `window.Appcues` recognizable to TypeScript
declare global {
  interface Window {
    Appcues: any;
  }
}

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    backgroundColor: kinsideTokens["--viridian-50"],
    padding: "8px 16px",
    alignItems: "center",
    borderRadius: `${kinsideTokens["--grid-unit"]} ${kinsideTokens["--grid-unit"]} 0px 0px`,
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },
  headerIcon: {
    color: kinsideTokens["--gray-0"],
    marginRight: theme.spacing(0.5),
  },
  headerTitle: {
    flex: 2,
    paddingLeft: kinsideTokens["--grid-unit"],
    color: kinsideTokens["--gray-0"],
    fontSize: kinsideTokens["--font-size-10"],
  },
  headerCloseButton: {
    color: kinsideTokens["--gray-0"],
    fontSize: kinsideTokens["--font-size-30"],
    cursor: "pointer",
    marginLeft: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(2),
    maxHeight: "368px", // shows partial 4th, indicating more below
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "unset",
      height: "100VH",
    },
  },
  bodySubheader: {
    fontSize: kinsideTokens["--font-size-0"],
    marginBottom: theme.spacing(2),
  },
  flowRow: {
    display: "flex",
    border: `1px solid ${kinsideTokens["--viridian-5"]}`,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      border: `1px solid ${kinsideTokens["--viridian-15"]}`,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  playIcon: {
    fontSize: kinsideTokens["--font-size-10"],
    color: kinsideTokens["--coral-20"],
    marginRight: theme.spacing(1),
    alignSelf: "center",
  },
  arrow: {
    position: "absolute",
    fontSize: "8px",
    width: theme.spacing(2),
    height: theme.spacing(2),
    "&::before": {
      content: "\"\"",
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  popper: {
    zIndex: theme.zIndex.modal,
    boxShadow: kinsideTokens["--shadow-20"],
    borderRadius: theme.spacing(1),
    padding: 0,
    "&[x-placement*=\"bottom\"] $arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${kinsideTokens["--viridian-50"]} transparent`,
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      borderRadius: 0,
      transform: "unset!important",
    },
  },
  popperContent: {
    backgroundColor: theme.palette.background.paper,
    width: "322px",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

type AppCuesPopperProps = {
  isVisible: boolean;
  onClose: () => void;
  anchorEl: any;
  onFlowsFetched: (hasFlows: boolean) => void;
};

export const AppCuesPopper: FunctionComponent<AppCuesPopperProps> = props => {
  const classes = useStyles();

  const [arrowRef, setArrowRef] = React.useState(null);

  const kinside_id = useSelector((state: any) => state.clientStateReducer.kinside_id);

  const fetchAppcuesLaunchpadFlows = async () => {
    try {
      const response = await fetch(`${process.env.kinsideBaseUri}/appcues/flows`, {
        method: "GET",
        headers: {
          Accept: "application/json", // explicitily request JSON
        },
        credentials: "include", // send the session cookie in the request to avoid 401 Unauthorized
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      return await response.json();
    }
    catch (error) {
      console.error("Error fetching Appcues Launchpad flows: ", error);

      return null;
    }
  };

  const [flows, setFlows] = React.useState(null);

  React.useEffect(() => {
    if (!flows) {
      const fetchFlows = async () => {
        const fetchedFlows = await fetchAppcuesLaunchpadFlows();
        setFlows(fetchedFlows);
        if (props.onFlowsFetched && typeof props.onFlowsFetched === "function") {
          props.onFlowsFetched(fetchedFlows && fetchedFlows.length > 0);
        }
      };
      fetchFlows();
    }
  });

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {
            `window.AppcuesSettings = {
               enableURLDetection: true
            };`
          }
        </script>
        <script src="https://fast.appcues.com/63524.js" />
        <script type="text/javascript">
          {
            `window.onload = () => {
              window.Appcues.identify('${kinside_id}', {
                userType: 'provider',
              });
            };`
          }
        </script>
      </Helmet>

      <Popper
        open={props.isVisible}
        anchorEl={props.anchorEl}
        placement="bottom"
        disablePortal={false}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <div className={classes.popperContent}>
          <div className={classes.header}>
            <FontAwesomeIcon icon={faCircleQuestion} className={classes.headerIcon} />
            <Typography variant="body2" className={classes.headerTitle}>HELP CENTER</Typography>
            <FontAwesomeIcon icon={faXmark} className={classes.headerCloseButton} onClick={props.onClose} />
          </div>
          <div className={classes.body}>
            <Typography variant="body1" className={classes.bodySubheader}>
              Welcome to the Kinside Help Center! {" "}
              Click the links below for videos to help you make the most of your Kinside account.
            </Typography>

            {flows && (
              flows.map(flow => (
                <div
                  className={classes.flowRow}
                  key={flow.id}
                  onClick={() => {
                    window.Appcues.show(flow.id);

                    // close the popper after a second to give Appcues time to load
                    setTimeout(() => {
                      props.onClose();
                    }, 500);
                  }}
                >
                  <FontAwesomeIcon icon={faCirclePlay} className={classes.playIcon} />
                  {flow.name}
                </div>
              ))
            )}
          </div>
        </div>
      </Popper>
    </>
  );
};

export default AppCuesPopper;
