import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Center, Child, Parent } from "@legup/legup-model";

import { Theme, createStyles, fade, makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";

import { kinsideTokens } from "@legup/legup-react-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChild, faMagnifyingGlass, faPeopleGroup, faSchoolFlag } from "@fortawesome/pro-light-svg-icons";

import { findEmail, findItem } from "../../actions/searchActions";
import strings from "../../infra/constants/strings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: "300px",
      maxHeight: "350px",
      overflow: "auto",
      backgroundColor: theme.palette.grey[200],
    },
    popper: {
      zIndex: theme.zIndex.modal,
      cursor: "pointer",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      transition: theme.transitions.create("width"),
      width: "100%",
      backgroundColor: kinsideTokens["--gray-0"],
      [theme.breakpoints.up("md")]: {
        width: 300,
        padding: theme.spacing(1, 1, 1, 7),
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    searchMore: {
      margin: theme.spacing(2),
      fontWeight: "bold",
      fontStyle: "italic",
    },
  }),
);

interface SearchResults {
  centers: Center[]
  parents: Parent[]
  children: Child[]
  emails: string[]
  moreItems: boolean
}

type AutoCompleteProps = {
  findEmail?: boolean;
  includePhone?: boolean;
  userTypes?: string[];
  onItemSelected: ((item: any, type: string) => void);
  trimInput?: boolean;
  placeholder?: string;
};

const AutoComplete = (props: AutoCompleteProps) => {
  const classes = useStyles(props);
  const [showSearchResults, setShowSearchResults] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [results, setResults] = React.useState<SearchResults>();

  const provider_id = useSelector((state: any) => state.clientStateReducer.provider_id);
  const searchResults = useSelector((state: any) => state.searchReducer.searchResults);
  const emails = useSelector((state: any) => state.searchReducer.emails);
  const moreEmails = useSelector((state: any) => state.searchReducer.moreEmails);
  const dispatch = useDispatch();
  const searchInput = useRef(null);

  React.useEffect(() => {
    if (props.findEmail && emails) {
      setResults({ centers: [], parents: [], children: [], emails, moreItems: moreEmails });
    }
    else if (!props.findEmail && searchResults) {
      setResults({ centers: searchResults.centers,
        parents: searchResults.parents,
        children: searchResults.children,
        emails: [],
        moreItems: searchResults.moreItems });
    }
  }, [searchResults, emails]);

  const doSearch = (e: any, text: string) => {
    if (props.findEmail) {
      dispatch(findEmail(text, props.includePhone, props.userTypes));
    }
    else {
      dispatch(findItem(provider_id, text));
    }
    setShowSearchResults(true);
  };

  const handleSearchChange = (e: any) => {
    let text = e.target.value;
    if (props.trimInput) {
      text = text.trim();
    }
    setSearchText(text);
    if (text.length > 1) {
      doSearch(e, text);
    }
    else {
      setShowSearchResults(false);
    }
  };

  const handleSearchKeyPress = (e: any) => {
    if (e.charCode === 13) {
      // Select the top item in the results - order is child, parent, center
      setShowSearchResults(false);
      if (results?.children?.length) {
        props.onItemSelected(results.children[0], "child");
      }
      else if (results?.parents?.length) {
        props.onItemSelected(results.parents[0], "parent");
      }
      else if (results?.centers?.length) {
        props.onItemSelected(results.centers[0], "center");
      }
      else if (props.findEmail) {
        // For emails, it's free form entry - so just return the text contents raw
        props.onItemSelected(searchText, "email");
      }
    }
  };

  const onSearchResultSelected = (name: string) => {
    // Route based on what was selected
    const items = name.split(".");
    setShowSearchResults(false);

    if (items[0] === "center") {
      const center = results.centers[items[1]];
      props.onItemSelected(center, "center");
    }
    else if (items[0] === "parent") {
      const parent = results.parents[items[1]];
      props.onItemSelected(parent, "parent");
    }
    else if (items[0] === "child") {
      const child = results.children[items[1]];
      props.onItemSelected(child, "child");
    }
    else if (items[0] === "email") {
      // For email because it's free form entry, also update the text box
      const email = results.emails[items[1]];
      setSearchText(email);
      props.onItemSelected(email, "email");
    }
  };

  const listKey: string = results ?
    `ctr:${results.centers.map(c => c.getId()).join(":")}:p:${results.parents.map(p => p.getId()).join(":")}:c:${results.children.map(c => c.getId()).join(":")}:e:${results.emails.join(":")}`
    : "none";

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <IconButton onClick={e => doSearch(e, searchText)}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </IconButton>
      </div>
      <InputBase
        placeholder={props.placeholder || (props.findEmail ? strings.main.searchEmail : strings.main.search)}
        inputRef={searchInput}
        onChange={handleSearchChange}
        onKeyPress={handleSearchKeyPress}
        value={searchText || ""}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
      <ClickAwayListener onClickAway={() => setShowSearchResults(false)}>
        <Popper
          className={classes.popper}
          placement="bottom-start"
          anchorEl={searchInput.current}
          open={!!results && showSearchResults}
          transition
        >
          <Paper elevation={0} className={classes.root}>
            {(results && results.moreItems) ? (
              <>
                <Typography className={classes.searchMore} variant="subtitle1">{strings.main.moreItems}</Typography>
                <Divider variant="fullWidth" />
              </>
            )
              : null}
            <List component="nav" aria-label="main mailbox folders" key={listKey}>
              {(results && !results.centers.length && !results.parents.length && !results.children.length && !results.emails.length) ? (
                <ListItem onClick={() => setShowSearchResults(false)} key="NoResult" value="NoResult">
                  <Typography className={classes.searchMore} variant="subtitle1">{strings.main.noResults}</Typography>
                </ListItem>
              )
                : null}
              {results ? results.children.map((c, idx) => (
                <ListItem onClick={() => onSearchResultSelected(`child.${idx}`)} key={`${c.getName()}`} value={`child.${c.getId()}`}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faChild} />
                  </ListItemIcon>
                  <ListItemText primary={c.getName()} />
                </ListItem>
              )) : null}
              {results ? results.parents.map((p, idx) => (
                <ListItem onClick={() => onSearchResultSelected(`parent.${idx}`)} key={`${p.getName()}`} value={`parent.${p.getId()}`}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faPeopleGroup} />
                  </ListItemIcon>
                  <ListItemText primary={p.getName()} />
                </ListItem>
              )) : null}
              {results ? results.centers.map((c, idx) => (
                <ListItem onClick={() => onSearchResultSelected(`center.${idx}`)} key={`${c.getName()}`} value={`center.${c.getId()}`}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faSchoolFlag} />
                  </ListItemIcon>
                  <ListItemText primary={c.getName()} />
                </ListItem>
              )) : null}
              {results ? results.emails.map((e, idx) => (
                <ListItem onClick={() => onSearchResultSelected(`email.${idx}`)} key={e} value={`email.${idx}`}>
                  <ListItemText primary={e} />
                </ListItem>
              )) : null}
            </List>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

export default AutoComplete;
