import SplitIO from "@splitsoftware/splitio-react/types/splitio/splitio";

// Set of Features for feature flags
// These values must match those set up in the Split Dashboard
export enum Feature {
  // Parent dashboard features
  parentDashboard = "Parent_Dashboard",
  socialSharing = "Social_Sharing",
  // Admin tool features
  uxSettings = "UX_Admin_Settings",
  legupFamilySeats = "Offer_LegUp_Family_Seats",
  comms = "CommsPreview",
  // Dev tools
  devTools = "Dev_Admin_tools",
  // Seat features
  fillSeat = "Fill_Seat",
  // Provider Onboarding
  onboardingSurvey = "Process_Wizard",
  // Online tuition signup
  tuitionSignup = "Tuition_Signup",
}

// Is the feature on or not?
export const featureEnabled = (props: { isReady?: boolean, treatments?: { [s: string]: SplitIO.TreatmentWithConfig } }, feature: Feature): boolean => {
  // We default to the feature is OFF unless the Split SDK is ready and explicitly tells us that the feature is ON
  // We may override this for some treatments and could do that within this file
  const enableFeature: boolean = props.isReady && props.treatments && props.treatments[feature]?.treatment === "on";

  return enableFeature;
};

// Is the feature on or not?
export const featureState = (props: { isReady?: boolean, treatments?: { [s: string]: SplitIO.TreatmentWithConfig } }, feature: Feature): string => {
  let state: string;

  // In this case, we look to see what the treatment state is
  // If the Split SDK isn't ready, then we will return "off"
  if (!props.isReady || !props.treatments || !props.treatments[feature]) {
    state = "off";
  }
  else {
    state = props.treatments[feature].treatment;
  }

  return state;
};
