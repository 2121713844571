import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { kinsideTokens } from "@legup/legup-react-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faExclamationCircle, faExclamationTriangle, faXmark } from "@fortawesome/pro-light-svg-icons";

import { renderStringWithHTML } from "../../infra/utils";

const useStyles = makeStyles(theme => ({
  "avatar-notification": {
    backgroundColor: kinsideTokens["--gold-20"],
  },
  "avatar-warning": {
    backgroundColor: kinsideTokens["--gold-20"],
  },
  "avatar-error": {
    backgroundColor: theme.palette.error.main,
  },
  "header-sticky-notification": {
    fontWeight: 600,
    fontSize: "31px",
    letterSpacing: "-0.2px",
    fontFamily: "serif",
    color: kinsideTokens["--periwinkle-80"],
  },
  "banner-sticky-notification": {
    fontSize: "20px",
    lineHeight: "28px",
    maxWidth: "85%",
    color: kinsideTokens["--periwinkle-80"],
  },
  "banner-notification": {
    fontSize: "20px",
    color: kinsideTokens["--gold-80"],
  },
  "banner-warning": {
    fontSize: "20px",
    color: kinsideTokens["--gold-80"],
  },
  "banner-error": {
    fontSize: "20px",
    color: "white",
  },
  closeButton: {
    color: "white",
    alignSelf: "flex-start",
    padding: "0 !important",
  },
  "paper-sticky-notification": {
    position: "relative",
    backgroundColor: kinsideTokens["--periwinkle-5"],
    marginBottom: theme.spacing(2),
    boxSizing: "content-box",
    width: "100%",
  },
  "paper-notification": {
    position: "relative",
    backgroundColor: kinsideTokens["--gold-0"],
    marginBottom: theme.spacing(2),
    boxSizing: "content-box",
    width: "100%",
  },
  "paper-warning": {
    position: "relative",
    backgroundColor: kinsideTokens["--gold-0"],
    border: `1px solid ${kinsideTokens["--gold-50"]}`,
    marginBottom: theme.spacing(2),
    boxSizing: "content-box",
    width: "100%",
  },
  "paper-error": {
    position: "relative",
    backgroundColor: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    boxSizing: "content-box",
    width: "100%",
  },
  grid: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  image: {
    width: "188px",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  linkDecorator: {
    textDecoration: "none",
    fontWeight: 300,
    fontSize: "18px",
  },
  readMore: {
    marginTop: theme.spacing(2),
  },
}));

type BannerProps = {
  header?: string,
  message?: string,
  content?: React.ReactNode,
  showClose?: boolean,
  button?: React.ReactNode,
  image?: string,
  type: "warning" | "notification" | "error" | "sticky-notification",
  onClose?: () => void,
};

const Banner = (props: BannerProps) => {
  const classes = useStyles(props);

  return (
    <Paper elevation={0} className={classes[`paper-${props.type}`]}>
      <Grid container wrap="nowrap" spacing={2} alignItems="center" className={classes.grid}>
        {props.type !== "sticky-notification" && (
          <Grid item>
            <Avatar className={classes[`avatar-${props.type}`]}>
              {props.type === "warning" 
                ? <FontAwesomeIcon icon={faExclamationTriangle} />
                : (props.type === "error" 
                  ? <FontAwesomeIcon icon={faExclamationCircle} /> 
                  : <FontAwesomeIcon icon={faBell} />
                )}
            </Avatar>
          </Grid>
        )}
        {!!props.message && (
          <Grid item>
            {!!props.header && (
              <Typography className={classes[`header-${props.type}`]}>{renderStringWithHTML(props.header)}</Typography>
            )}
            <Typography className={classes[`banner-${props.type}`]}>{renderStringWithHTML(props.message)}</Typography>
            {!!props.button && props.type === "sticky-notification" && (
              <Grid item className={classes.readMore}>
                {props.button}
                <a href="https://www.kinside.com/terms-of-service" className={classes.linkDecorator} target="_blank" rel="noreferrer">Read terms</a>
              </Grid>
            )}
          </Grid>
        )}
        {!!props.image && (
          <Grid item>
            <img src={props.image} className={classes.image} alt="" />
          </Grid>

        )}
        {!!props.content && (
          <Grid item>
            {props.content}
          </Grid>
        )}
        {!!props.button && props.type !== "sticky-notification" && (
          <Grid item direction="column-reverse">
            {props.button}
          </Grid>
        )}
        <div className="flex-grow" />
        {!!props.showClose && (
          <Grid item className={classes.closeButton}>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={props.onClose}
            >
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default Banner;
