import React from "react";

import { Theme, ThemeProvider, makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { theme } from "@legup/legup-react-components";

import { renderStringWithHTML } from "../../infra/utils";
import strings from "../../infra/constants/strings";

const useStyles = makeStyles((uiTheme: Theme) => ({
  button: {
    marginTop: uiTheme.spacing(1),
    padding: uiTheme.spacing(1),
  },
  content: {
    margin: "40px 0 0",
    flexGrow: 1,
    padding: uiTheme.spacing(12),
    paddingTop: "24px",
    minHeight: "100%",
  },
  title: {
    flexGrow: 1,
    justifyContent: "flex-start",
    justifySelf: "center",
    display: "flex",
    marginLeft: uiTheme.spacing(2),
    marginTop: uiTheme.spacing(8),
    textAlign: "left",
  },
}));

interface AuthResultPageProps {
  description: string;
  onLogin?: () => void;
}

const AuthResultPage = (props: AuthResultPageProps) => {
  const classes = useStyles(props);

  const renderCopyright = () => (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://kinside.com/">
        {strings.companyName}
      </Link>{" "}
      {new Date().getFullYear()}
      .
    </Typography>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <main className={classes.content}>
          <div />
          <Box height="100%">
            <Grid container>
              <Grid item xs>
                <Typography variant="h1" noWrap>
                  <img
                    src="https://res.cloudinary.com/kinside/image/upload/v1643079260/app_assets/kinside_logo/kinside-word-dark.svg"
                    alt={strings.altLogo}
                    height="30"
                    className="logo"
                  />
                </Typography>
              </Grid>
              {props.onLogin ? (
                <Grid item container xs justify="flex-end">
                  <Button
                    id="qsLoginBtn"
                    className={classes.button}
                    component="span"
                    variant="contained"
                    color="primary"
                    onClick={props.onLogin}
                  >
                    {strings.login.buttonLogin}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              align="center"
              className={classes.title}
            >
              {renderStringWithHTML(props.description)}
            </Typography>
          </Box>
        </main>
        <div className="py-4">
          <Divider />
          {renderCopyright()}
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default AuthResultPage;
