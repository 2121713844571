import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/pro-light-svg-icons";

import { kinsideTokens } from "@legup/legup-react-components";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import strings from "../../infra/constants/strings";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    paddingInline: theme.spacing(5),
    backgroundColor: kinsideTokens["--viridian-10"],
    [theme.breakpoints.down("xs")]: { 
      paddingInline: "10px",
    },
  },
  text: {
    display: "inline-block",
    color: kinsideTokens["--vidirian-80"],
  },
  icon: {
    display: "inline-block",
    borderRadius: "24px",
    background: kinsideTokens["--viridian-5"],
    padding: "4px 6px",
    marginRight: "7px",
    verticalAlign: "middle",
  },
  locationsSelect: {
    width: "200px",
    margin: 0,
  },
  selectInput: {
    borderRadius: "8px",
    height: "32px",
  },
}));

type CenterLocationProps = {
  locations: Array<{name: string, id: string}>,
  currentCenter: string
  onChangeCenter: (id: string) => void
  hideProvider?: boolean,
};

const CenterLocation = (props: CenterLocationProps) => {  
  const classes = useStyles(props);
  const [currentCenterId, setCurrentCenterId] = useState(undefined);

  const onChangeCenter = (id: string) => {
    setCurrentCenterId(id);
    props.onChangeCenter(id === "all" ? undefined : id);
  };

  useEffect(() => {
    setCurrentCenterId(props.currentCenter);
  }, [props.currentCenter]);

  return (
    <div
      className={`${classes.container} py-2`}
    >
      <span>
        <FontAwesomeIcon icon={faBuilding} className={classes.icon} />
      </span>
      <span>
        <Typography
          variant="body1"
          className={classes.text}
        >
          {strings.locations.title}
        </Typography>
      </span>
      <span>
        <FormControl size="small" className={`py-0 ml-2 ${classes.locationsSelect}`}>
          <Select
            variant="outlined"
            key="centers"
            className={`py-0 ${classes.selectInput}`}
            value={currentCenterId || "all"}
            onChange={e => onChangeCenter(e.target.value as string)}
          >
            {!props.hideProvider && (
              <MenuItem key="view-all" value="all">
                {strings.main.allLocations}
              </MenuItem>
            ) }
            {props.locations?.map(c => (
              <MenuItem key={`view-${c.id}`} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </span>
    </div>
  );
};

export default CenterLocation;
