import querystring from "querystring";

import React from "react";
import Router from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { Loading } from "@legup/legup-react-components";

import { signInTokenProvider } from "../../actions/authAction";
import { setAdminUnit, setBrand, setEmail, setGroups, setIdToken, setMarketId, setNavigatorId, 
  setParentId, setProviderId, setRelationship, setUserId, setUserName, setUserPages, setUserRoles } from "../../actions/clientStateActions";

import strings from "../../infra/constants/strings";

interface LoggedInCallbackProps {
  queryParams?: {[s: string]: string};
  page?: string;
  redirectTo?: string;
}

const LoggedInCallback = (props: LoggedInCallbackProps) => {
  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
  } = useAuth0();

  const currentAuthToken = useSelector((state: any) => state.authReducer.currentAuthToken);
  const authError = useSelector((state: any) => state.authReducer.errorCode);
  const authBrand = useSelector((state: any) => state.authReducer.brand);
  const id_token = useSelector((state: any) => state.clientStateReducer.id_token);
  const email = useSelector((state: any) => state.clientStateReducer.email);
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const brand = useSelector((state: any) => state.clientStateReducer.brand);
  const navigatorLogin = useSelector((state: any) => state.clientStateReducer.navigatorLogin);
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function verifyToken() {
      const token = await getAccessTokenSilently();
      const group: string = window.location.href.startsWith(process.env.parentRedirectUri)
        ? navigatorLogin ? "navigator" : "parent"
        : "provider";
      let partner = brand;
      if (!partner) {
        const storageBrand = JSON.parse(localStorage.getItem("legup:brand"));
        if (storageBrand) {
          partner = storageBrand;
          dispatch(setBrand(partner));
        }
      }
      dispatch(signInTokenProvider(token, group, user.name, user.email, partner?.id));
    }

    if (isAuthenticated && user) {
      if (currentAuthToken === undefined) {
        verifyToken();
      }
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (currentAuthToken && currentAuthToken.id_token) {
      if (!groups) {
        const idt = currentAuthToken.getIdToken();
        const em = currentAuthToken.getEmail();
        const provider_id = currentAuthToken.getProviderId();
        const admin_unit = currentAuthToken.getIsAdminUnit();
        const parent_id = currentAuthToken.getParentId();
        const navigator_id = currentAuthToken.getNavigatorId();
        const relationship = currentAuthToken.getRelationship();
        const user_id = currentAuthToken.getUserId();
        const legup_list_id = currentAuthToken.getMarketId();
        const name = currentAuthToken.getName();
        const pages = currentAuthToken.getPages();
        const roles = currentAuthToken.getRoles();

        dispatch(setProviderId(provider_id));
        dispatch(setAdminUnit(admin_unit));
        dispatch(setGroups(currentAuthToken.getGroups()?.toString()));
        dispatch(setIdToken(idt));
        dispatch(setEmail(em));
        dispatch(setRelationship(relationship));
        dispatch(setUserId(user_id));
        dispatch(setMarketId(legup_list_id));
        dispatch(setParentId(parent_id));
        dispatch(setNavigatorId(navigator_id));
        dispatch(setUserName(name));
        dispatch(setUserPages(pages));
        dispatch(setUserRoles(roles));

        // If we got a brand from auth, use that
        if (authBrand) {
          dispatch(setBrand(authBrand));
        }
      }
      else {
        // OK, we are good to go
        Router.replace(props.redirectTo || "/");
      }
    }
  }, [currentAuthToken]);

  React.useEffect(() => {
    // Will need to check expiration - make sure token is still valid
    if (isAuthenticated && id_token && email) {
      Router.replace(props.redirectTo || "/");
    }
  }, [id_token, email]);

  React.useEffect(() => {
    if (isAuthenticated && authError) {
      Router.replace(props.redirectTo || "/");
    }
  }, [authError]);

  // If there was an error, let's display it
  if (props.queryParams.error) {
    const queryParams = querystring.stringify({ authError: props.queryParams.error_description || strings.login.authErrorGeneric });
    Router.replace(`/?${queryParams}`);

    return null;
  }

  // Wait until we decide to redirect
  return (
    <Loading title={strings.loading} />
  );
};

export default LoggedInCallback;
