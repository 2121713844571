import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Theme, makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { acceptTerms } from "../../actions/authAction";
import { setAcceptedTerms } from "../../actions/clientStateActions";

import strings from "../../infra/constants/strings";

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    paddingTop: theme.spacing(2),
  },
}));

interface AcceptTermsProps {
  open: boolean;
  onAccept: (() => void);
}

const AcceptTerms = (props: AcceptTermsProps) => {
  const classes = useStyles(props);
  const [accepted, setAccepted] = React.useState(false);

  const acceptSuccess = useSelector((state: any) => state.authReducer.acceptSuccess);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const success = process.env.email ? true : acceptSuccess;

    dispatch(setAcceptedTerms(new Date()));
    if (success) {
      props.onAccept();
    }
  }, [acceptSuccess]);

  const onCloseTerms = () => {
    if (!accepted) {
      if (typeof window !== "undefined") {
        window.alert(strings.auth.mustAcceptTerms);
      }
    }
    else {
      // Awesome, let's accept
      dispatch(acceptTerms());
    }
  };

  const label = (
    <span>{strings.auth.readTerms}
      <a
        href="https://www.legup.care/terms"
        target="_blank"
        rel="noreferrer"
      >
        {strings.auth.termsOfUse}
      </a>
    </span>
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
      onClose={onCloseTerms}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{strings.alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {strings.auth.acceptTerms}
          <div /><FormControlLabel
            className={classes.checkbox}
            control={(
              <Checkbox
                checked={accepted}
                onChange={e => setAccepted(e.target.checked)}
                name="accepted"
                color="primary"
              />
            )}
            label={label}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseTerms} color="primary">
          {strings.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptTerms;
