import dynamic from "next/dynamic";
import Router from "next/router";
import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Consumer } from "@rails/actioncable";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";

import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Center, Child, Parent } from "@legup/legup-model";

import { kinsideTokens } from "@legup/legup-react-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBars,
  faCircleUser,
  faEllipsisVertical,
} from "@fortawesome/pro-light-svg-icons";

import {
  faCircleQuestion,
} from "@fortawesome/pro-solid-svg-icons";

import { setActionCount, setUserInteractions } from "../../actions/clientStateActions";
import { confirmFeesStructure, getNewToursCount, getUnreadBadge, incrementUnreadMessages, readActionItems } from "../../actions/providerActions";
import { actionableSeats } from "../../actions/seatActions";
import { readNotifications } from "../../actions/userActions";
import strings from "../../infra/constants/strings";
import { Event, trackEvent, trackEventLegacy } from "../../infra/tracking";
import AutoComplete from "../common/AutoComplete";
import NotificationToast from "../common/NotificationToast";

import CenterLocation from "../common/CenterLocation";

import { generateKinsideJWT } from "../../infra/authUtils";
import Banner from "../common/Banner";

import { mainListItems } from "./ListItems";

import AppCuesPopper from "./AppCuesPopper";

// Lazy load the tabs
const ActionTab = dynamic(() => import("../Tabs/ActionTab"));
const AdminToolsTab = dynamic(() => import("../Tabs/AdminToolsTab"));
const AdminUnitTab = dynamic(() => import("../Tabs/AdminUnitTab"));
const LegupTab = dynamic(() => import("../Tabs/LegupTab"));
const ProfileTab = dynamic(() => import("../Tabs/ProfileTab"));
const CommsTab = dynamic(() => import("../Tabs/CommsTab"));

const OpenSeatsTab = dynamic(() => import("../Tabs/OpenSeatsTab"));
const ConciergeTab = dynamic(() => import("../Tabs/ConciergeTab"));
const DepositTab = dynamic(() => import("../Tabs/DepositTab"));
const EnrollmentTab = dynamic(() => import("../Tabs/EnrollmentTab"));
const ParentTab = dynamic(() => import("../Tabs/ParentTab"));
const OnboardCenterTab = dynamic(() => import("../Tabs/OnboardCenterTab"));

const ClassroomsTab = dynamic(() => import("../Tabs/ClassroomsTab"));
const ToursTab = dynamic(() => import("../Tabs/TourTab"));
const ContractTab = dynamic(() => import("../Tabs/ContractTab"));
const SchedulesTab = dynamic(() => import("../Tabs/SchedulesTab"));
const InfoTab = dynamic(() => import("../Tabs/InfoTab"));
const WaitlistTab = dynamic(() => import("../Tabs/WaitlistTab"));
const UserTab = dynamic(() => import("../Tabs/UserTab"));
const WelcomeLegupTab = dynamic(() => import("../Tabs/WelcomeLegupTab"));
const WelcomeNavigatorTab = dynamic(() => import("../Tabs/WelcomeNavigatorTab"));
const WelcomeParentTab = dynamic(() => import("../Tabs/WelcomeParentTab"));
const WelcomeProviderTab = dynamic(() => import("../Tabs/WelcomeProviderTab"));
// Pages off the Tools tab
const AddFamilyTab = dynamic(() => import("../Tabs/Tools/AddFamilyTab"));
const AdHocQueryTab = dynamic(() => import("../Tabs/Tools/AdHocQueryTab"));
const DuplicateTab = dynamic(() => import("../Tabs/Tools/DuplicateTab"));
const ProviderLoginTab = dynamic(() => import("../Tabs/Tools/ProviderLoginTab"));
const ReminderTab = dynamic(() => import("../Tabs/Tools/ReminderTab"));
const WaitlistHeadersTab = dynamic(() => import("../Tabs/Tools/WaitlistHeadersTab"));
const ViewDashboardTab = dynamic(() => import("../Tabs/Tools/ViewDashboardTab"));
const ViewEmailTab = dynamic(() => import("../Tabs/Tools/ViewEmailTab"));
const OpenedEmailTab = dynamic(() => import("../Tabs/Tools/OpenedEmailTab"));
const ProviderTextOptInTab = dynamic(() => import("../Tabs/Tools/ProviderTextOptInTab"));
const RegistrationCodeTab = dynamic(() => import("../Tabs/Tools/RegistrationCodeTab"));
const MarketPlaceDisplayTab = dynamic(() => import("../Tabs/Tools/MarketPlaceDisplayTab"));
const DemographicsTab = dynamic(() => import("../Tabs/Tools/DemographicsTab"));
const TransfersTab = dynamic(() => import("../Tabs/Tools/TransfersTab"));
const PartnersTab = dynamic(() => import("../Tabs/Tools/PartnersTab"));
const LegupFamilySeatsTab = dynamic(() => import("../Tabs/Tools/LegupFamilySeatsTab"));
const PartnerProviderTab = dynamic(() => import("../Tabs/Tools/PartnerProviderTab"));
const NavigatorsTab = dynamic(() => import("../Tabs/Tools/NavigatorsTab"));
const ImportProviderTab = dynamic(() => import("../Tabs/Tools/ImportProviderTab"));
const ProviderActionTab = dynamic(() => import("../Tabs/Tools/ProviderActionTab"));
const SubsidiesTab = dynamic(() => import("../Tabs/Tools/SubsidiesTab"));
const DataFilesTab = dynamic(() => import("../Tabs/Tools/DataFilesTab"));
const UxSettingsTab = dynamic(() => import("../Tabs/Tools/UxSettingsTab"));
const StuckPaymentsTab = dynamic(() => import("../Tabs/Tools/StuckPaymentsTab"));
const MessageDelaysTab = dynamic(() => import("../Tabs/Tools/MessageDelaysTab"));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.kinside.com/">
        {strings.companyName}
      </Link>{" "}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const drawerWidth = 256 - 32;

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const providerLetterColors = [
  kinsideTokens["--coral-20"],
  kinsideTokens["--fuschia-20"],
  kinsideTokens["--rust-20"],
  kinsideTokens["--viridian-20"],
  kinsideTokens["--gold-40"],
  kinsideTokens["--periwinkle-40"],
];

const providerLetterBgColor = providerLetterColors[randomIntFromInterval(0, providerLetterColors.length - 1)] || providerLetterColors[0];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: kinsideTokens["--viridian-0"],
    height: "100vh",
    overflowY: "auto",
  },
  main: {
    flexGrow: 1,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "16px 0",
    left: 0,
    position: "fixed",
    backgroundColor: "white",
  },
  menuButtonHidden: {
    display: "none",
  },
  hide: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    justifyContent: "flex-start",
    justifySelf: "center",
    display: "flex",
    marginLeft: "16px",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: "0px 8px 8px 0 rgba(0, 63, 51, 0.12)",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  providerName: {
    fontSize: "16px",
    fontWeight: 500,
    color: kinsideTokens["--gray-0"],
    marginLeft: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  providerNameContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 24px",
    alignItems: "center",
  },
  providerLetterCircle: {
    backgroundColor: providerLetterBgColor,
    borderRadius: 32,
    height: 32,
    width: 32,
    flex: "none",
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      fontWeight: 700,
      fontSize: 16,
      color: kinsideTokens["--gray-0"],
      textTransform: "uppercase",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    borderRight: "none",
    boxShadow: "0 2px 5px rgba(0,0,0,.26);",
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: "100%",
  },
  contentMargin: {
    margin: "65px 0 0",
  },
  centerLocationSelect: {
    margin: "65px 0 0 0",
  },
  notifyMargin: {
    margin: "90px 8px 0",
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    height: "36px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  container: {
    flexDirection: "column",
    minHeight: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  userAvatar: {
    width: 48,
    height: 48,
    background: theme.palette.primary.main,
    fontSize: "18px",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  iconButton: {
    marginTop: theme.spacing(-2),
  },
  fixedHeight: {
    height: 240,
  },
  divider: {
    height: 55,
    backgroundColor: "white",
  },
  toggleIcon: {
    marginLeft: 65,
  },
  contact: {
    position: "absolute",
    bottom: 50,
    left: 0,
  },
  itemIcon: {
    minWidth: 32,
    marginRight: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  messageIcon: {
    fontSize: 24,
  },
  messagesBadge: {
    borderRadius: 32,
    padding: "0 7px",
    margin: "auto",
    width: "fit-content",
    backgroundColor: kinsideTokens["--coral-20"],
    color: "white",
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "20px",
  },
  searchDiv: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  showMoreButton: {
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  helpCenterButton: {
    color: kinsideTokens["--viridian-20"],
    "&:hover path": {
      fill: kinsideTokens["--viridian-50"],
    },
    "&:active path": {
      fill: kinsideTokens["--viridian-50"],
    },
  },
  helpCenterButtonOpen: {
    color: kinsideTokens["--viridian-50"],
  },
  confirmBtn: {
    backgroundColor: kinsideTokens["--periwinkle-20"],
    color: kinsideTokens["--periwinkle-80"],
    marginRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: kinsideTokens["--periwinkle-30"],
      color: kinsideTokens["--periwinkle-80"],
    },
    "&:active": {
      backgroundColor: kinsideTokens["--periwinkle-40"],
      color: kinsideTokens["--periwinkle-80"],
    },
  },
}));

type DashboardProps = {
  page: string;
  lastLogin?: Date;
  queryParams?: any;
  logout?: () => void;
  reLogin?: () => void;
};

export const Dashboard: FunctionComponent<DashboardProps> = props => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showCentersSelect, setShowCentersSelect] = React.useState(false);
  const [showFeeBanner, setShowFeeBanner] = React.useState(false);
  const [actioncable, setActioncable] = React.useState<undefined | Consumer>(undefined);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const groups = useSelector((state: any) => state.clientStateReducer.groups);
  const pages = useSelector((state: any) => state.clientStateReducer.pages);
  const email = useSelector((state: any) => state.clientStateReducer.email);
  const relationship = useSelector((state: any) => state.clientStateReducer.relationship);
  const provider_id = useSelector((state: any) => state.clientStateReducer.provider_id);
  const navigator_id = useSelector((state: any) => state.clientStateReducer.navigator_id);
  const parent_id = useSelector((state: any) => state.clientStateReducer.parent_id);
  const user_id = useSelector((state: any) => state.clientStateReducer.user_id);
  const actionCount = useSelector((state: any) => state.providerReducer.actionCenterData ?
    ["offers", "questions", "tours", "requestPaperwork", "sentPaperwork"]
      .map(key => state.providerReducer.actionCenterData[key])
      .reduce((acc, items) => acc.concat(items || []), [])
      .length : 0
  );
  const avatar = useSelector((state: any) => state.clientStateReducer.avatar);
  const brand = useSelector((state: any) => state.clientStateReducer.brand);
  const providerName = useSelector((state: any) => state.clientStateReducer.provider_name);
  const acceptedTerms = useSelector((state: any) => state.clientStateReducer.acceptedTerms);
  const name = useSelector((state: any) => state.clientStateReducer.name);
  const userNotifications = useSelector((state: any) => state.userReducer.notifications);
  const actionSeats = useSelector((state: any) => state.providerReducer.actionableSeats);
  const centerList = useSelector((state: any) => state.providerReducer.currentProvider?.centers);
  const currentProviderId: string | undefined = useSelector((state: any) => state.providerReducer.currentProvider?.getId());
  const adminUnit = useSelector((state: any) => state.clientStateReducer.admin_unit);
  const user = useSelector((state: any) => state.userReducer.currentUser);
  const kinsideOnboarded = useSelector((state: any) => state.providerReducer.currentProvider?.kinside_onboarded);
  const kinsideId = useSelector((state: any) => state.providerReducer.currentProvider?.kinside_id);
  const kinsideFees = useSelector((state: any) => state.providerReducer.currentProvider?.kinside_fees);
  const currentProvider = useSelector((state: any) => state.providerReducer.currentProvider);
  const idToken = useSelector((state: any) => state.clientStateReducer.id_token);
  const unreadMessagesCount = useSelector((state: any) => state.providerReducer.unreadMessages);
  const newToursCount = useSelector((state: any) => state.providerReducer.newTours);

  // app cues
  const [isHelpCenterVisible, setIsHelpCenterVisible] = React.useState(false);
  const [hasAppcuesFlows, setHasAppcuesFlows] = React.useState(false);

  const appCuesAnchorRef = React.useRef(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (groups !== "provider") return;

    dispatch(getUnreadBadge(email));
    dispatch(getNewToursCount(provider_id));
  }, [email, groups]);

  React.useEffect(() => {
    if (!kinsideId || typeof window === "undefined" || !!actioncable) return;

    let cable: Consumer;
    const initTerminal = async () => {
      const jwt = await generateKinsideJWT(email, groups, idToken, currentProviderId);
      const { createConsumer } = await import("@rails/actioncable");
      cable = createConsumer(`${process.env.kinsideWebsocketUrl}/cable?jwt=${jwt}`);
      cable.subscriptions.create(
        {
          channel: "ConversationsChannel",
          type: "Provider",
          id: kinsideId,
        },
        {
          received(data) {
            if (data.inc_badge) dispatch(incrementUnreadMessages());
          },
        }
      );

      setActioncable(cable);
    };

    initTerminal();
  }, [kinsideId]);

  // eslint-disable-next-line arrow-body-style
  React.useEffect(() => {
    // disconnect on unmount
    return () => {
      if (actioncable) actioncable.disconnect();
    };
  }, []);

  React.useEffect(() => {
    setShowCentersSelect(
      (props.page === "profile" && centerList?.length > 1) ||
      (props.page === "profile" && groups === "legup" && !!centerList && !!props.queryParams.pid)
    );
  }, [groups, props.page, centerList, props.queryParams]);

  React.useEffect(() => {
    if (props.queryParams?.action === "logout") handleLogout();
  }, [props.queryParams]);

  React.useEffect(() => {
    // And set up chat capability if applicable
    if ((groups === "navigator") && process.env.intercomAppId) {
      const accepted = acceptedTerms ? new Date(acceptedTerms) : new Date();
      const intercomParams: any = {
        app_id: process.env.intercomAppId,
        name,
        email,
        created_at: Math.floor(accepted.valueOf() / 1000),
      };

      intercomParams.partner = brand?.name;
      intercomParams.navigator_id = navigator_id;

      (window as any).Intercom("boot", intercomParams);
      (window as any).intercomMessenger = true;
    }
  }, []);

  React.useEffect(() => {
    if (email && props.lastLogin) {
      dispatch(readNotifications(email, props.lastLogin));
    }
  }, [props.lastLogin, email]);

  React.useEffect(() => {
    setShowFeeBanner(groups === "provider" && (kinsideFees ? !parseInt(kinsideFees.version) : !!currentProvider));
  }, [currentProvider, kinsideFees]);

  React.useEffect(() => {
    if (provider_id) {
      dispatch(readActionItems(provider_id, null));
    }
  }, [dispatch, provider_id]);

  React.useEffect(() => {
    if (userNotifications) {
      if ((groups === "provider") && provider_id) {
        dispatch(actionableSeats(provider_id, null, false));
      }
      const user_interactions = { ...userNotifications };
      delete user_interactions.seats;
      dispatch(setUserInteractions(user_interactions));
    }
  }, [userNotifications, provider_id]);

  React.useEffect(() => {
    if (actionSeats) {
      let count: number = actionSeats.seats.filter(s => (s.getStatus() && ["seat offered", "sent paperwork"].indexOf(s.getStatus().status) === -1)).length;
      count += actionSeats.tourRequests || 0;
      dispatch(setActionCount(count));
    }
  }, [actionSeats]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onClickUser = () => {
    trackEventLegacy(email, groups, Event.clickTab, "user");
    Router.push("/user");
  };

  const navigateToKinside = () => {
    location.replace(process.env.kinsideBaseUri);
  };

  const handleLogout = () => {
    if ((window as any).intercomMessenger) {
      (window as any).Intercom("shutdown");
      (window as any).intercomMessenger = false;
    }

    // Logout from Auth0
    props.logout();
  };

  const onSearch = (item: any, type: string) => {
    // Route based on what was selected
    if (type === "center") {
      const center = item as Center;
      Router.push(`/profile?cid=${center.getId()}`);
    }
    else if (type === "parent") {
      const parent = item as Parent;
      Router.push(`/families?pid=${parent.getId()}`);
    }
    else if (type === "child") {
      const child = item as Child;
      Router.push(`/families?pid=${child.getParentId()}&cid=${child.getId()}`);
    }
  };

  const partnerName = (groups === "provider")
    ? providerName
    : (brand?.showName ? brand.name : undefined);

  const isAdmin = groups === "legup";

  const drawer = (
    <>
      <Toolbar className="x4">
        <img
          src="https://res.cloudinary.com/kinside/image/upload/v1628157536/app_assets/kinside_logo/light.svg"
          alt={strings.altLogo}
          height={30}
        />
      </Toolbar>

      {partnerName && (
        <>
          <Divider style={{ backgroundColor: kinsideTokens["--viridian-40"] }} />
          <Grid container justifyContent="center" direction="row">
            <Grid item xs={12}>
              <div className={classes.providerNameContainer}>
                <div className={classes.providerLetterCircle}>
                  <span>{Array.from(partnerName || "-")[0]}</span>
                </div>
                <Typography className={classes.providerName}>{partnerName || ""}</Typography>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {!partnerName &&
        <Divider style={{ backgroundColor: kinsideTokens["--viridian-40"] }} />}

      <List>{mainListItems(props.page, email, groups, relationship, actionCount, classes, user, kinsideOnboarded, unreadMessagesCount, newToursCount)}</List>
    </>
  );

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={onClickUser}>{strings.tabs.userProfile}</MenuItem>
      {isAdmin && <MenuItem onClick={navigateToKinside}>{strings.tabs.kinsideAdmin}</MenuItem>}
      <MenuItem key="logout" value="logout" onClick={handleLogout}>{strings.main.logout}</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={onClickUser}
        >
          {avatar ? ((avatar.length === 1)
            ? (
              <Avatar className={classes.userAvatar} alt={strings.centerList.altManaged}>
                {avatar}
              </Avatar>
            )
            : <Avatar className={classes.userAvatar} alt={strings.centerList.altManaged} src={avatar} />)
            : <FontAwesomeIcon icon={faCircleUser} />}
        </IconButton>
        <p>User Actions</p>
      </MenuItem>
    </Menu>
  );

  const usesFullCanvas = () => (((props.page === "main") && (groups !== "navigator"))
    || ((props.page === "viewdashboard") && (groups === "legup")));

  const renderHubspot = () => {
    if (!(window.location.href.includes("family.legup.care") ||
      ((groups === "provider") && window.location.href.includes("app.kinside.com")))) {
      return null;
    }

    return (
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="https://js-na1.hs-scripts.com/4769325.js" />
      </Helmet>
    );
  };

  const renderTab = () => {
    switch (props.page) {
      case "main":
        if (groups === "legup") {
          return <WelcomeLegupTab queryParams={props.queryParams} />;
        }
        if (groups === "provider") {
          return <WelcomeProviderTab queryParams={props.queryParams} />;
        }
        if (groups === "parent") {
          return <WelcomeParentTab queryParams={props.queryParams} />;
        }
        if (groups === "navigator") {
          return <WelcomeNavigatorTab queryParams={props.queryParams} />;
        }

        return null;

      case "action":
        return <ActionTab queryParams={props.queryParams} />;
      case "comms":
        return <CommsTab queryParams={props.queryParams} />;
      case "deposit":
        return <DepositTab queryParams={props.queryParams} />;
      case "duplicates":
        return <DuplicateTab queryParams={props.queryParams} />;
      case "enrollments":
        return <EnrollmentTab queryParams={props.queryParams} />;
      case "onboard":
        return <OnboardCenterTab queryParams={props.queryParams} />;
      case "classrooms":
        return <ClassroomsTab queryParams={props.queryParams} />;
      case "tours":
        return <ToursTab queryParams={props.queryParams} />;
      case "waitlists":
        return <WaitlistTab queryParams={props.queryParams} />;
      case "profile":
        return <ProfileTab queryParams={props.queryParams} />;
      case "adminunits":
        return <AdminUnitTab queryParams={props.queryParams} />;
      case "openseats":
        return <OpenSeatsTab queryParams={props.queryParams} />;
      case "concierge":
        return <ConciergeTab />;
      case "info":
        return <InfoTab queryParams={props.queryParams} />;
      case "families":
        return <ParentTab queryParams={props.queryParams} />;
      case "tools":
        return <AdminToolsTab queryParams={props.queryParams} />;
      case "legup":
        return <LegupTab queryParams={props.queryParams} />;
      case "contracts":
        return <ContractTab queryParams={props.queryParams} />;
      case "schedules":
        return <SchedulesTab queryParams={props.queryParams} />;
      case "user":
        return <UserTab onLogout={props.reLogin} />;
      case "addfamily":
        return <AddFamilyTab queryParams={props.queryParams} />;
      case "adhocquery":
        return <AdHocQueryTab queryParams={props.queryParams} />;
      case "providerlogin":
        return <ProviderLoginTab />;
      case "importprovider":
        return <ImportProviderTab />;
      case "reminders":
        return <ReminderTab />;
      case "waitlistheaders":
        return <WaitlistHeadersTab queryParams={props.queryParams} />;
      case "viewemails":
        return <ViewEmailTab />;
      case "openedemails":
        return <OpenedEmailTab />;
      case "viewdashboard":
        return <ViewDashboardTab queryParams={props.queryParams} />;
      case "providertextoptin":
        return <ProviderTextOptInTab />;
      case "registrationcode":
        return <RegistrationCodeTab />;
      case "marketplacedisplay":
        return <MarketPlaceDisplayTab queryParams={props.queryParams} />;
      case "demographics":
        return <DemographicsTab queryParams={props.queryParams} />;
      case "transfers":
        return <TransfersTab queryParams={props.queryParams} />;
      case "partners":
        return <PartnersTab queryParams={props.queryParams} />;
      case "navigators":
        return <NavigatorsTab queryParams={props.queryParams} />;
      case "legupfamilyseats":
        return <LegupFamilySeatsTab />;
      case "partnerprovider":
        return <PartnerProviderTab queryParams={props.queryParams} />;
      case "provideraction":
        return <ProviderActionTab queryParams={props.queryParams} />;
      case "subsidies":
        return <SubsidiesTab queryParams={props.queryParams} />;
      case "datafiles":
        return <DataFilesTab queryParams={props.queryParams} />;
      case "uxsettings":
        return <UxSettingsTab />;
      case "stuckpayments":
        return <StuckPaymentsTab queryParams={props.queryParams} />;
      case "messagedelays":
        return <MessageDelaysTab />;
      default:
        return null;
    }
  };

  // "user" is a special case.  Everyone should have access to their user profile, and it's not in the main menu so
  // the function to return the list of pages doesn't include "user".
  if (props.page !== "user" && pages && !pages.some((p: any) => p.name === props.page || p.subitems?.indexOf(props.page) > -1)) {
    Router.replace(pages[0].path);

    return null;
  }

  // handle select center
  const onSelectCenter = id => {
    const router = Router;
    if (id) router.query.cid = id;
    else {
      delete router.query.cid;
      if (groups === "legup") {
        router.query.pid = currentProviderId;
      }
    }

    Router.push(router, undefined, { shallow: true });
  };

  const handleHelpCenterToggle = () => {
    setIsHelpCenterVisible(!isHelpCenterVisible);
  };

  const onConfirmingFeesStructure = () => {
    trackEvent(Event.buttonClicked, {
      event_description: Event.providerTrxPaymentsOptIn,
      button_label: "I understand",
      user_type: "provider",
    });
    dispatch(confirmFeesStructure(provider_id));
    setShowFeeBanner(false);
  };

  const renderNewFeesNotification = (
    <>
      {showFeeBanner && (
        <div className={classes.notifyMargin}>
          <Banner
            header="We're streamlining payments!"
            message="All transactions will now have a flat 2% service fee to providers.
            There are no more variable fees on waitlists and deposits.
            Enjoy hassle-free payments!"
            type="sticky-notification"
            image="https://res.cloudinary.com/kinside/image/upload/v1668029192/app_assets/illustration-girl.png"
            button={(
              <Button variant="contained" color="secondary" className={classes.confirmBtn} onClick={onConfirmingFeesStructure}>
                I understand
              </Button>
            )}
          />
        </div>
      )}
    </>
  );

  return (
    <div className={classes.root}>
      {renderHubspot()}
      <AppCuesPopper
        isVisible={isHelpCenterVisible}
        onClose={handleHelpCenterToggle}
        anchorEl={appCuesAnchorRef.current}
        onFlowsFetched={hasFlows => setHasAppcuesFlows(hasFlows)}
      />
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          {(groups !== "parent") && (groups !== "navigator") && (
            <div className={classes.searchDiv}>
              <AutoComplete onItemSelected={onSearch} />
            </div>
          )}
          <div className={classes.grow} />
          {hasAppcuesFlows && (
            <IconButton
              aria-label="Help Center"
              onClick={handleHelpCenterToggle}
              className={`${classes.helpCenterButton} ${isHelpCenterVisible ? classes.helpCenterButtonOpen : null}`}
              ref={appCuesAnchorRef}
            >
              <FontAwesomeIcon icon={faCircleQuestion} />
            </IconButton>
          )}
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              className={classes.iconButton}
              aria-label="menu"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {avatar ? ((avatar.length === 1)
                ? (
                  <Avatar className={classes.userAvatar} alt={strings.centerList.altManaged}>
                    {avatar}
                  </Avatar>
                )
                : <Avatar className={classes.userAvatar} alt={strings.centerList.altManaged} src={avatar} />)
                : <FontAwesomeIcon icon={faCircleUser} />}
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              className={classes.showMoreButton}
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <nav className={classes.drawer} aria-label="legup page links">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            open
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      {usesFullCanvas() ? (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {renderNewFeesNotification}
              {renderTab()}
            </Grid>
            <Grid item xs={12}>
              <div className="py-4">
                <Divider className="mb-4" />
                <Copyright />
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container>
          <Grid item>
            {renderNewFeesNotification}
          </Grid>
          <Container disableGutters maxWidth={props.page === "profile" ? false : "lg"}>
            {showCentersSelect && (
              <div className={showFeeBanner ? undefined : classes.centerLocationSelect}>
                <CenterLocation
                  locations={centerList?.map(center => ({ id: center.center_id, name: center.name })) || []}
                  onChangeCenter={id => onSelectCenter(id)}
                  currentCenter={props.queryParams?.cid}
                  hideProvider={adminUnit}
                />
              </div>
            )}
            <main className={`${classes.content} ${(showCentersSelect || showFeeBanner) ? undefined : classes.contentMargin}`}>
              <div />
              <Box maxWidth="lg">
                {renderTab()}
              </Box>
            </main>
            <div className="py-4">
              <Divider className="mb-4" />
              <Copyright />
            </div>
          </Container>
        </Grid>
      )}
      <NotificationToast />
    </div>
  );
};

export default Dashboard;
