import React from "react";
import Router from "next/router";
import { Auth0Provider } from "@auth0/auth0-react";

import { useDispatch, useSelector } from "react-redux";

import * as Sentry from "@sentry/nextjs";

import { Dashboard } from "../Dashboard/Dashboard";

import {
  setAdminUnit, setBrand,
  setEmail,
  setGroups,
  setIdToken,
  setMarketId, setNavigatorLogin,
  setRefreshToken,
  setRelationship, setUserName, setUserPages, setUserRoles,
} from "../../actions/clientStateActions";

import InnerAuth from "./InnerAuth";
import LoggedInCallback from "./LoggedInCallback";

interface AppState {
  returnTo: string;
  timestamp?: string;
}

interface AuthProviderProps {
  queryParams?: {[s: string]: string};
  page?: string;
  redirectTo?: string;
}

const AuthProvider = (props: AuthProviderProps) => {
  const last_login = useSelector((state: any) => state.authReducer.last_login);
  const id_token = useSelector((state: any) => state.clientStateReducer.id_token);
  const email = useSelector((state: any) => state.clientStateReducer.email);
  const [loggingOut, setLoggingOut] = React.useState<string>();
  const [keepBrand, setKeepBrand] = React.useState(false);
  const [redirectTo, setRedirectTo] = React.useState<string>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (loggingOut) {
      dispatch(setIdToken(undefined));
      dispatch(setEmail(undefined));
      dispatch(setRefreshToken(undefined));
      dispatch(setGroups(undefined));
      dispatch(setRelationship(undefined));
      dispatch(setMarketId(undefined));
      dispatch(setAdminUnit(undefined));
      dispatch(setUserName(undefined));
      dispatch(setUserRoles(undefined));
      dispatch(setUserPages(undefined));

      if (!keepBrand) {
        dispatch(setBrand(undefined));
        dispatch(setNavigatorLogin(undefined));
      }

      Router.replace(loggingOut);
    }
  }, [loggingOut]);

  const onRedirectCallback = (appState: AppState | undefined) => {
    if (appState && appState.returnTo) {
      // Redirect to this page within the app
      setRedirectTo(appState.returnTo);
    }
    else {
      Router.replace("/loggedin");
    }
  };

  const onReLogin = () => {
    // We need to first logout, then login again
    setKeepBrand(true);
    setLoggingOut(process.env.redirectUri);
  };

  const onLogout = () => {
    setLoggingOut(process.env.kinsideLogoutUri);
  };

  const family: boolean = window.location.href.startsWith(process.env.parentRedirectUri);

  if (!family && !id_token && !loggingOut) {
    Router.replace(process.env.kinsideBaseUri);
  }
  else if (!family && email) {
    Sentry.setUser({ email });
  }

  return (
    <>
      {
        family ? (
          <Auth0Provider
            domain={family ? process.env.auth0ParentDomain : process.env.auth0ProviderDomain}
            clientId={family ? process.env.auth0ParentClientId : process.env.auth0ProviderClientId}
            audience={family ? process.env.auth0ParentAudience : process.env.auth0ProviderAudience}
            scope="openid profile email"
            redirectUri={`${window.location.origin}/loggedin`}
            onRedirectCallback={onRedirectCallback}
          >
            {props.page === "loggedin" ? <LoggedInCallback {...props} redirectTo={redirectTo} />
              : <InnerAuth {...props} />}
          </Auth0Provider>
        ) : (
          <>
            {id_token ? (
              <Dashboard
                page={props.page}
                queryParams={props.queryParams}
                lastLogin={last_login}
                logout={onLogout}
                reLogin={onReLogin}
              />
            ) :
              (
                <></>
              )}
          </>
        )
      }
    </>
  );
};

export default AuthProvider;
