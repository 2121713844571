import querystring from "querystring";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import CssBaseline from "@material-ui/core/CssBaseline";

import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/nextjs";

import { AuthToken, Child, Parent } from "@legup/legup-model";
import { Loading, theme } from "@legup/legup-react-components";

import { setAcceptedTerms, setAdminUnit, setBrand, setEmail, setGroups, setIdToken, setMarketId, setNavigatorId, 
  setNavigatorLogin, setParentId, setProviderId, setRefreshToken, setRelationship, setUserId, setUserName, setUserPages, setUserRoles } from "../../actions/clientStateActions";

import strings from "../../infra/constants/strings";

import { Dashboard } from "../Dashboard/Dashboard";
import SignupForm from "../Signup/SignupForm";

import AcceptTerms from "./AcceptTerms";
import AuthResultPage from "./AuthResultPage";

interface AppState {
  returnTo: string;
  timestamp?: string;
}

interface InnerAuthProps {
  queryParams?: {[s: string]: string};
  page?: string;
  redirectTo?: string;
}

const InnerAuth = (props: InnerAuthProps) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();

  if (isAuthenticated) {
    Sentry.setUser({ email: user.email });
  }

  const [showAcceptTerms, setShowAcceptTerms] = React.useState(false);
  const [loggingOut, setLoggingOut] = React.useState<string>();
  const [keepBrand, setKeepBrand] = React.useState(false);
  const [signupForm, setSignupForm] = React.useState<any>();
  const [registeringParent, setRegisteringParent] = React.useState(false);

  const acceptedApi = useSelector((state: any) => state.authReducer.accepted_terms);
  const acceptedClient = useSelector((state: any) => state.clientStateReducer.acceptedTerms);
  const authError = useSelector((state: any) => state.authReducer.errorCode);
  const last_login = useSelector((state: any) => state.authReducer.last_login);
  const id_token = useSelector((state: any) => state.clientStateReducer.id_token);
  const email = useSelector((state: any) => state.clientStateReducer.email);
  const clientBrand = useSelector((state: any) => state.clientStateReducer.brand);
  const providerKinsideOnboarded = useSelector((state: any) => state.providerReducer.currentProvider?.kinside_onboarded);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (email && registeringParent) {
      // OK, an e-mail has been set so let's try loading again
      setRegisteringParent(false);
      window.location.reload();
    }
  }, [email]);

  React.useEffect(() => {
    if ((acceptedApi !== undefined) || (acceptedClient !== undefined)) {
      setShowAcceptTerms(!acceptedApi && !acceptedClient);
      if (acceptedApi) {
        dispatch(setAcceptedTerms(acceptedApi));
      }
    }
  }, [acceptedApi, acceptedClient]);

  React.useEffect(() => {
    if (loggingOut) {
      dispatch(setIdToken(undefined));
      dispatch(setEmail(undefined));
      dispatch(setRefreshToken(undefined));
      dispatch(setGroups(undefined));
      dispatch(setRelationship(undefined));
      dispatch(setMarketId(undefined));
      dispatch(setAdminUnit(undefined));
      dispatch(setUserName(undefined));
      dispatch(setUserRoles(undefined));
      dispatch(setUserPages(undefined));

      if (!keepBrand) {
        dispatch(setBrand(undefined));
        dispatch(setNavigatorLogin(undefined));
      }

      logout({ returnTo: loggingOut });  
    }
  }, [loggingOut]);

  React.useEffect(() => {
    const readForm = async () => {
      let form;

      const queryParams = querystring.stringify({ brand: clientBrand.name });
      const resp = await fetch(`/api/waitlist/form?${queryParams}`);
      const data = await resp.json();
      form = (data.success) ? data.form : { error: "APIERROR" };

      setSignupForm(form);
    };

    if (authError?.startsWith("UNREGISTEREDUSER:")) {
      readForm();
    }  
  }, [authError]);

  React.useEffect(() => {
    if (props.queryParams) {
      let brand: { id: string, name: string, showName: boolean, logo_url: string, palette: { [s: string]: string } } | undefined;

      if (props.queryParams.brand) {
        try {
          brand = JSON.parse(Buffer.from(props.queryParams.brand, "base64").toString("utf-8"));
        }
        catch (e) {
          brand = undefined;
        }
      }

      let isNavigator = false;
      if (brand) {
        isNavigator = props.queryParams?.nav === "1";
        dispatch(setBrand(brand));
        localStorage.setItem("legup:brand", JSON.stringify(brand));
      }

      dispatch(setNavigatorLogin(isNavigator));
    }
  }, [props.queryParams]);

  const onReLogin = () => {
    // We need to first logout, then login again
    const family: boolean = window.location.href.startsWith(process.env.parentRedirectUri);
    setKeepBrand(true);
    setLoggingOut(`${family ? process.env.parentRedirectUri : process.env.redirectUri}`);
  };

  const onLogout = () => {
    const family: boolean = window.location.href.startsWith(process.env.parentRedirectUri);
    const redirectUrl = (family || !providerKinsideOnboarded) ? "https://legup.care" : process.env.kinsideLogoutUri;

    setLoggingOut(redirectUrl);
  };

  const handleReauthenticate = async (data: { parent: Parent, parent2?: Parent, child: Child }, metadata: string) => {
    // Great - now that we've entered them into the system, let's register them
    const req = await fetch("/api/auth/register", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        user: {
          email: data.parent.getEmail(),
          first_name: data.parent.getFirstName(),
          last_name: data.parent.getLastName(),
          name: data.parent.getName(),
        },
        group: "parent",
        token: metadata,
      }),
    });

    if (req.status === 200) {
      const d2 = await req.json();
      if (d2.success) {
        // Complete the login process
        const authToken = new AuthToken();
        authToken.buildFromJSON(d2.authToken);

        const idt = authToken.getIdToken();
        const em = authToken.getEmail();
        const provider_id = authToken.getProviderId();
        const admin_unit = authToken.getIsAdminUnit();
        const roles = authToken.getRoles();
        const pages = authToken.getPages();
        const parent_id = authToken.getParentId();
        const navigator_id = authToken.getNavigatorId();
        const relationship = authToken.getRelationship();
        const user_id = authToken.getUserId();
        const legup_list_id = authToken.getMarketId();
        const name = authToken.getName();
  
        setRegisteringParent(true);
        dispatch(setProviderId(provider_id));
        dispatch(setAdminUnit(admin_unit));
        dispatch(setGroups(authToken.getGroups()?.toString()));
        dispatch(setIdToken(idt));
        dispatch(setEmail(em));
        dispatch(setRelationship(relationship));
        dispatch(setUserId(user_id));
        dispatch(setMarketId(legup_list_id));
        dispatch(setParentId(parent_id));
        dispatch(setNavigatorId(navigator_id));
        dispatch(setUserName(name));
        dispatch(setUserRoles(roles));
        dispatch(setUserPages(pages));
      }
    }
  };

  if (isLoading) {
    // Don't do other checks until Auth0 is loaded
    return null;
  }

  if (loggingOut) {
    // We're about to redirect away
    return null;
  } 
  
  if (props.queryParams && props.queryParams.authError) {
    return (
      <AuthResultPage
        description={strings.login.authError.replace("{Error}", props.queryParams.authError)}
        onLogin={onReLogin}
      />
    );
  }

  if (showAcceptTerms) {
    return (
      <AcceptTerms open={showAcceptTerms} onAccept={() => setShowAcceptTerms(false)} />
    );
  } 
  
  if (authError) {
    if (authError.startsWith("UNREGISTEREDUSER:")) {
      // This is a family that isn't registered and trying to login through the partner site
      // We should give them an opportunity to register and then let them through if they do
      if (!signupForm) {
        return <Loading title={strings.loading} />;
      }

      const values = authError.split(":");

      return (
        <SignupForm
          form={signupForm}
          email={values.slice(2).join(":")}
          metadata={values[1]}
          partnerName={clientBrand.name}
          onComplete={handleReauthenticate}
        />
      );
    }
    
    const description: string = authError.startsWith("INVALIDUSER:")
      ? strings.auth.invalidUser.replace("{email}", authError.substr("INVALIDUSER:".length))
      : (strings.errorCodes[authError] ? strings.errorCodes[authError] : authError);

    return (
      <AuthResultPage
        description={description}
        onLogin={onReLogin}
      />
    );
  } 
  
  if (user && !user.email_verified) {
    const family: boolean = window.location.href.startsWith(process.env.parentRedirectUri);

    return (
      <AuthResultPage
        description={strings.login[family ? "verifyEmailParent" : "verifyEmailProvider"]}
        onLogin={onReLogin}
      />
    );
  }

  if (!id_token || !email) {
    let appState: AppState;

    if (window.location.pathname && window.location.pathname.length > 1) {
      let returnTo: string = window.location.pathname;
      if (props.queryParams && Object.keys(props.queryParams).length) {
        const queryParams = querystring.stringify(props.queryParams);
        returnTo = `${returnTo}?${queryParams}`;
      }
      appState = { returnTo };
    }
    loginWithRedirect({ appState, brand: props.queryParams?.brand, nav: props.queryParams?.nav === "1" ? "1" : "0" });

    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dashboard 
        page={props.page} 
        queryParams={props.queryParams}
        lastLogin={last_login}
        logout={onLogout}
        reLogin={onReLogin}
      />
    </ThemeProvider>
  );
};

export default InnerAuth;
