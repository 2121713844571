import React from "react";
import { useSelector } from "react-redux";

import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Page, User } from "@legup/legup-model";

import { SplitTreatments } from "@splitsoftware/splitio-react";

import { kinsideTokens } from "@legup/legup-react-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAddressBook,
  faBell,
  faCalendarDay,
  faCalendarLinesPen,
  faChair,
  faChild,
  faCircleQuestion,
  faCity,
  faFileInvoice,
  faFileInvoiceDollar,
  faListUl,
  faMagnifyingGlass,
  faMessage,
  faMessages,
  faPeopleGroup,
  faPlus,
  faSchoolFlag,
  faSparkles,
  faStore,
  faTableCellsLarge,
  faTag,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";

import { generateKinsideJWT } from "../../infra/authUtils";
import { Event, trackEventLegacy } from "../../infra/tracking";
import strings from "../../infra/constants/strings";
import { Feature, featureEnabled } from "../../infra/featureFlags";

const mapPageNameToFaIcon = {
  main: faTableCellsLarge,
  onboard: faPlus,
  classrooms: faChair,
  tours: faCalendarDay,
  waitlists: faAddressBook,
  enrollments: faFileInvoice,
  payments: faFileInvoiceDollar,
  openseats: faMagnifyingGlass,
  contracts: faTag,
  adminunits: faCity,
  families: faChild,
  legup: faListUl,
  tools: faWrench,
  comms: faMessages,
  help: faCircleQuestion,
  action: faBell,
  familyDetails: faPeopleGroup,
  info: faSchoolFlag,
  familyHelp: faMessages,
  concierge: faMessages,
  profile: faSparkles,
  schedules: faCalendarLinesPen,
  businessInfo: faStore,
  provMessages: faMessage,
  newTours: faCalendarDay,
  new_enrollments: faFileInvoice,
};

interface TabProps {
  children: any;
  name: string;
  target?: string;
  email: string;
  groups: string;
  [s: string]: any;
}

const pathMapping = {
  businessInfo: "/business",
  provMessages: "/messages",
  waitlist: "/waitlist",
  payments: "/payments",
  tours: "/tours",
  enrollments: "/enrollments",
};

const Tab = (props: TabProps) => {
  const tabSelected = () => {
    trackEventLegacy(props.email, props.groups, Event.clickTab, props.name);
  };

  const id_token = useSelector((state: any) => state.clientStateReducer.id_token);
  const providerId = useSelector((state: any) => state.clientStateReducer.provider_id);

  const handleCallback = async (payload: string) => {
    trackEventLegacy(props.email, props.groups, Event.clickTab, props.name);
    if (pathMapping[payload]) {
      window.open(`${process.env.kinsideBaseUri}${pathMapping[payload]}`, "_self");
    }
  };

  // TODO: We're leveraging the target field to determine that this is a callback
  // that we'll handle programmatically. Probably should add another field in the future
  if (props.target?.startsWith("c.")) {
    // This isn't a link, but rather a callback to the dashboard
    const payload = props.target.slice(2);

    return (
      <MenuItem
        style={{
          backgroundColor: props.selected
            ? kinsideTokens["--viridian-80"]
            : kinsideTokens["--viridian-50"],
        }}
        {...props}
        onClick={() => handleCallback(payload)}
      >
        {props.children}
      </MenuItem>
    );
  }

  return (
    <MenuItem
      {...props}
      component="a"
      target={`${props.target || "_self"}`}
      href={`${props.path}`}
      onClick={tabSelected}
      style={{
        backgroundColor: props.selected
          ? kinsideTokens["--viridian-60"]
          : kinsideTokens["--viridian-50"],
      }}
    >
      {props.children}
    </MenuItem>
  );
};

export const mainListItems = (
  page: string,
  email: string,
  groups: string,
  relationship: string | undefined,
  actions: number,
  classes: any,
  user: User | undefined,
  kinsideOnboarded: boolean | undefined,
  unreadMessagesCount: number | undefined,
  newToursCount: number | undefined,
) => {
  // eslint-disable-next-line
  let allowedPages: Page[] = useSelector(
    (state: any) => state.clientStateReducer.pages
  );
  // eslint-disable-next-line
  let provider_id: any = useSelector(
    (state: any) => state.clientStateReducer.provider_id
  );

  // remove search/openseats tab for providers, (but keep for admin)
  allowedPages = allowedPages
                 .filter(page => groups === "provider" ? page.name === "businessInfo" ? kinsideOnboarded && !user?.provider_id.toLowerCase().includes("au") : page.name !== "openseats" : true);

  const needDivider = currentPage => {
    if (groups === "legup") {
      if (currentPage.name === "openseats"
        || currentPage.name === "tools") {
        return true;
      }
    }
    else if (currentPage.name === "schedules"
        || currentPage.name === "tools"
        || currentPage.name === "main") {
      return true;
    }

    return false;
  };

  const showMessagesBadge = (pageName: string) => pageName === "provMessages" && unreadMessagesCount > 0;
  const showToursBadge = (pageName: string) => pageName === "newTours" && newToursCount > 0;

  return (
    <SplitTreatments names={[Feature.comms]} attributes={{ provider_id }}>
      {splitProps => {
        if (provider_id && !featureEnabled(splitProps, Feature.comms)) {
          allowedPages = allowedPages.filter(obj => obj.path !== "/comms");
        }

        return (
          <>
            {allowedPages &&
              allowedPages.map(currPage => {
                const isSelected =
                  page === currPage.name ||
                  currPage.subitems?.indexOf(page) > -1;

                return (
                  <React.Fragment key={currPage.page_id}>
                    {needDivider(currPage) && (
                      <Divider variant="fullWidth" className="my-1" style={{ backgroundColor: kinsideTokens["--viridian-40"] }} />
                    )}
                    <Tab
                      name={currPage.name}
                      path={currPage.path}
                      selected={isSelected}
                      target={currPage.target}
                      email={email}
                      groups={groups}
                    >
                      {/* We have to do something different if it's the action menu item, so only do this first block if it's not the action menu item */}
                      {currPage.name !== "action" && (
                        <ListItemIcon className={classes.itemIcon}>
                          <FontAwesomeIcon
                            icon={mapPageNameToFaIcon[currPage.name]}
                            style={{
                              color: isSelected
                                ? kinsideTokens["--gold-20"]
                                : kinsideTokens["--viridian-0"],
                            }}
                          />
                        </ListItemIcon>
                      )}
                      {/* Special case - if it's the action menu item then we need to add the badge icon for the action count */}
                      {currPage.name === "action" && (
                        <ListItemIcon className={classes.itemIcon}>
                          <Badge badgeContent={actions} color="secondary">
                            <FontAwesomeIcon
                              icon={mapPageNameToFaIcon[currPage.name]}
                              style={{
                                color: isSelected
                                  ? kinsideTokens["--gold-20"]
                                  : kinsideTokens["--viridian-0"],
                              }}
                            />
                          </Badge>
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            color: isSelected
                              ? kinsideTokens["--gold-20"]
                              : kinsideTokens["--gray-0"],
                            fontWeight: "normal",
                          },
                        }}
                        primary={
                          strings.tabs[currPage.display_locale_string] ||
                          currPage.display_alt_text
                        }
                      />
                      { showMessagesBadge(currPage.name) && (
                        <span className={classes.messagesBadge}>{unreadMessagesCount > 99 ? "99+" : unreadMessagesCount}</span>
                      )}
                      { showToursBadge(currPage.name) && (
                        <span className={classes.messagesBadge}>{newToursCount > 99 ? "99+" : newToursCount}</span>
                      )}
                    </Tab>
                  </React.Fragment>
                );
              })}
          </>
        );
      }}
    </SplitTreatments>
  );
};
