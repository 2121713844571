import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faExclamationCircle, faXmark } from "@fortawesome/pro-light-svg-icons";

import { kinsideTokens } from "@legup/legup-react-components";

import strings from "../../infra/constants/strings";

const mapStateToProps = (state: any) => ({
  addCenterSuccess: state.centerReducer.addSuccess,
  updateCenterSuccess: state.centerReducer.updateSuccess,
  deleteCenterSuccess: state.centerReducer.deleteSuccess,
  updateCenterCMSSuccess: state.centerReducer.updateProfileCMSSuccess,
  updateDisplayOptionsSuccess: state.centerReducer.updateDisplayOptionsSuccess,
  updateTourSchedulesSuccess: state.centerReducer.updateTourSchedulesSuccess,
  sendWaitlistFormSuccess: state.centerReducer.sendWaitlistFormSuccess,
  setAgeGroupSuccess: state.centerReducer.setAgeGroupSuccess,
  addProviderSuccess: state.providerReducer.addSuccess,
  updateProviderSuccess: state.providerReducer.updateSuccess,
  updateNoWaiveSubsidiesSuccess: state.providerReducer.updateNoWaiveSubsidiesSuccess,
  updateOnboardingSuccess: state.providerReducer.updateOnboardingSuccess,
  signupSuccess: state.providerReducer.signupSuccess,
  removeUserSuccess: state.providerReducer.removeUserSuccess,
  addParentSuccess: state.parentReducer.addSuccess,
  updateParentSuccess: state.parentReducer.updateSuccess,
  updateParentSubsidiesSuccess: state.parentReducer.updateSubsidiesSuccess,
  mergeChildrenSuccess: state.parentReducer.mergeChildrenSuccess,
  addChildSuccess: state.childReducer.addSuccess,
  addNoteSuccess: state.childReducer.addNoteSuccess,
  updateChildSuccess: state.childReducer.updateSuccess,
  addClassroomSuccess: state.classroomReducer.addSuccess,
  updateClassroomSuccess: state.classroomReducer.updateSuccess,
  addCouponSuccess: state.couponReducer.addSuccess,
  updateCouponSuccess: state.couponReducer.updateSuccess,
  addLeadSuccess: state.leadReducer.addSuccess,
  updateLeadSuccess: state.leadReducer.updateSuccess,
  addAgeGroupSuccess: state.ageGroupReducer.addSuccess,
  updateAgeGroupSuccess: state.ageGroupReducer.updateSuccess,
  deleteAgeGroupSuccess: state.ageGroupReducer.deleteSuccess,
  addSpotSuccess: state.waitlistReducer.addSpotSuccess,
  removeSpotSuccess: state.waitlistReducer.removeSpotSuccess,
  reactivateSpotSuccess: state.waitlistReducer.reactivateSpotSuccess,
  updateSpotSuccess: state.waitlistReducer.updateSpotSuccess,
  fillSpotSuccess: state.waitlistReducer.fillSuccess,
  importListSuccess: state.waitlistReducer.importSuccess,
  setFeesSuccess: state.waitlistReducer.setFeesSuccess,
  addSeatSuccess: state.seatReducer.addSuccess,
  updateSeatSuccess: state.seatReducer.updateSuccess,
  offerSeatSuccess: state.seatReducer.offerSuccess,
  offerFamilySeatSuccess: state.seatReducer.offerFamilySuccess,
  updateSeatStateSuccess: state.seatReducer.updateStateSuccess,
  removeSeatSuccess: state.seatReducer.removeSuccess,
  pauseSeatSuccess: state.seatReducer.pauseSuccess,
  offerLegupFamiliesSuccess: state.seatReducer.offerLegupFamiliesSuccess,
  splitSeatSuccess: state.seatReducer.splitSuccess,
  updateUserSuccess: state.userReducer.updateSuccess,
  addProgramSuccess: state.programReducer.addSuccess,
  updateProgramSuccess: state.programReducer.updateSuccess,
  copyProgramSuccess: state.programReducer.copySuccess,
  deleteProgramSuccess: state.programReducer.deleteSuccess,
  addTourSuccess: state.tourReducer.addSuccess,
  updateTourSuccess: state.tourReducer.updateSuccess,
  removeTourSuccess: state.tourReducer.removeSuccess,
  addScheduleSuccess: state.tourReducer.addScheduleSuccess,
  updateScheduleSuccess: state.tourReducer.updateScheduleSuccess,
  removeScheduleSuccess: state.tourReducer.removeScheduleSuccess,
  addAdminUnitSuccess: state.adminunitReducer.addSuccess,
  updateAdminUnitSuccess: state.adminunitReducer.updateSuccess,
  addNavigatorSuccess: state.navigatorReducer.addSuccess,
  updateNavigatorSuccess: state.navigatorReducer.updateSuccess,
  updateQuestionSuccess: state.leguplistReducer.updateQuestionSuccess,
  uploadFileSuccess: state.feedbackReducer.uploadSuccess,
  removeFileSuccess: state.feedbackReducer.removeSuccess,
  submitFeedbackSuccess: state.feedbackReducer.submitSuccess,
  errorCodes: [
    state.ageGroupReducer.errorCode,
    state.adminunitReducer.errorCode,
    state.centerReducer.errorCode,
    state.providerReducer.errorCode,
    state.parentReducer.errorCode,
    state.childReducer.errorCode,
    state.navigatorReducer.errorCode,
    state.classroomReducer.errorCode,
    state.couponReducer.errorCode,
    state.waitlistReducer.errorCode,
    state.seatReducer.errorCode,
    state.feedbackReducer.errorCode,
    state.linkReducer.errorCode,
    state.tourReducer.errorCode,
    state.userReducer.errorCode,
  ],
  feedbackText: state.feedbackReducer.feedbackText,
});

const styles = theme => ({
  success: {
    backgroundColor: kinsideTokens["--viridian-0"],
    border: `1px solid ${kinsideTokens["--viridian-50"]}`,
    color: kinsideTokens["--viridian-80"],
  },
  error: {
    backgroundColor: kinsideTokens["--rust-0"],
    border: `1px solid ${kinsideTokens["--rust-50"]}`,
    color: kinsideTokens["--rust-80"],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  loading: {
    backgroundColor: "white",
    color: "black",
  },
  circle: {
    marginLeft: 130,
  },
});

type ToastProps = {
  addCenterSuccess?: boolean,
  updateCenterSuccess?: boolean,
  deleteCenterSuccess?: boolean,
  updateCenterCMSSuccess?: boolean,
  updateDisplayOptionsSuccess?: boolean,
  updateTourSchedulesSuccess?: boolean,
  sendWaitlistFormSuccess?: boolean,
  setAgeGroupSuccess?: boolean,
  addProviderSuccess?: boolean,
  updateProviderSuccess?: boolean,
  updateNoWaiveSubsidiesSuccess?: boolean,
  signupSuccess?: boolean,
  removeUserSuccess?: boolean,
  addParentSuccess?: boolean,
  updateParentSuccess?: boolean,
  updateParentSubsidiesSuccess?: boolean,
  mergeChildrenSuccess?: boolean,
  addChildSuccess?: boolean,
  addNoteSuccess?: boolean,
  updateChildSuccess?: boolean,
  addCouponSuccess?: boolean,
  updateCouponSuccess?: boolean,
  addLeadSuccess?: boolean,
  updateLeadSuccess?: boolean,
  addAgeGroupSuccess?: boolean,
  updateAgeGroupSuccess?: boolean,
  deleteAgeGroupSuccess?: boolean,
  addClassroomSuccess?: boolean,
  updateClassroomSuccess?: boolean,
  addSeatSuccess?: boolean,
  updateSeatSuccess?: boolean,
  offerSeatSuccess?: boolean,
  offerFamilySeatSuccess?: boolean,
  updateSeatStateSuccess?: boolean,
  removeSeatSuccess?: boolean,
  pauseSeatSuccess?: boolean,
  offerLegupFamiliesSuccess?: boolean,
  splitSeatSuccess?: boolean,
  reactivateSpotSucces?: boolean,
  addSpotSuccess?: boolean,
  removeSpotSuccess?: boolean,
  updateSpotSuccess?: boolean,
  fillSpotSuccess?: boolean,
  importListSuccess?: boolean,
  setFeesSuccess?: boolean,
  updateUserSuccess?: boolean,
  addProgramSuccess?: boolean,
  updateProgramSuccess?: boolean,
  copyProgramSuccess?: boolean,
  deleteProgramSuccess?: boolean,
  addTourSuccess?: boolean,
  updateTourSuccess?: boolean,
  removeTourSuccess?: boolean,
  addScheduleSuccess?: boolean,
  updateScheduleSuccess?: boolean,
  removeScheduleSuccess?: boolean,
  addAdminUnitSuccess?: boolean,
  updateAdminUnitSuccess?: boolean,
  addNavigatorSuccess?: boolean,
  updateNavigatorSuccess?: boolean,
  updateQuestionSuccess?: boolean,
  uploadFileSuccess?: boolean,
  removeFileSuccess?: boolean,
  submitFeedbackSuccess?: boolean,
  errorCodes?: string[],
  feedbackText?: string,
  classes: any,
};

type ToastState = {
  open: boolean;
  message: string;
  duration: number;
  type: string;
  loading: boolean;
};

class NotificationToast extends React.Component<ToastProps, ToastState> {
  constructor(props: ToastProps) {
    super(props);
    this.state = {
      open: false,
      message: "",
      duration: 4000,
      type: "",
      loading: false,
    };
  }

  componentDidUpdate(prevProps: any) {
    // Did an error code change?
    let err;
    let showingError = false;
    if (prevProps.errorCodes !== this.props.errorCodes) {
      // Load a string of the one that changed
      this.props.errorCodes.forEach((e, idx) => {
        if (e !== prevProps.errorCodes[idx]) {
          err = strings.errorCodes[e] || e;
        }
      });
    }

    Object.keys(strings.notifications).forEach(p => {
      showingError = showingError || this.checkSuccess(prevProps, p, err);
    });

    if (!showingError && err) {
      // Well, there's some kind of error to show!
      this.setState({ open: true, type: "error", message: err });
    }
  }

  checkSuccess(prevProps: any, property: string, err: string | undefined): boolean {
    let changed = false;
    if (prevProps[property] !== this.props[property] && this.props[property] !== undefined) {
      this.setState({ loading: true });
      changed = true;
      // Special case for feedback
      const display = (property === "submitFeedbackSuccess") ? this.props.feedbackText : property;

      if (display) {
        if (this.props[property]) {
          this.setState({
            loading: false,
            open: true,
            type: "success",
            message: strings.notifications[display].success,
          });
        }
        else if (this.props[property] === false) {
          this.setState({
            loading: false,
            open: true,
            type: "error",
            message: (err) ? `${strings.notifications[display].fail}. ${err}` : strings.notifications[display].fail,
          });
        }
      }
      else {
        this.setState({ loading: false });
      }
    }

    return changed;
  }

  onClose() {
    this.setState({ open: false });
  }

  render() {
    const classes = this.props.classes;

    const loading = (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.state.loading}
      >
        <SnackbarContent
          className={classes.loading}
          aria-describedby="client-snackbar"
          message={(
            <span id="client-snackbar" className={classes.message}>
              <Typography>Saving ...</Typography>
              <div className={classes.circle}>
                <CircularProgress color="secondary" />
              </div>
            </span>
          )}
        />
      </Snackbar>
    );

    const successCheck = (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.state.open}
        autoHideDuration={this.state.duration}
        onClose={this.onClose.bind(this)}
      >
        <SnackbarContent
          className={clsx(classes[this.state.type], classes[this.state.type])}
          id="snack-notification"
          aria-describedby="client-snackbar"
          message={(
            <span id="client-snackbar" className={classes.message}>
              {this.state.type === "success" ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className={clsx(classes.icon, classes.iconVariant)}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className={clsx(classes.icon, classes.iconVariant)}
                />
              )}
              {this.state.message}
            </span>
          )}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.onClose.bind(this)}
            >
              <FontAwesomeIcon icon={faXmark} className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );

    const content = this.state.loading ? loading : successCheck;

    return <>{content}</>;
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(NotificationToast);
